

.reactor-btn-jeux{
    max-width: 20vmin;
    min-width: 20vmin;
    width: 100%;
    max-height: 23vmin;
    min-height: 23vmin;
    height: 100%;
    margin: 0vmin 6.5vmin;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.reactor-jeu-soustitre{
    font-weight: 100;
    font-size: 1vmin;
}


.btn-jeu.reactor-Jeu.Cashor{
    background-color: var(--color-Cashor-face);
}
.btn-jeu.reactor-border-jeux.Cashor::after{
    background-color: var(--color-Cashor);
}

.btn-jeu.reactor-Jeu.X-Ray{
    background-color: var(--color-X-Ray-face);
}
.btn-jeu.reactor-border-jeux.X-Ray::after{
    background-color: var(--color-X-Ray);
}

.btn-jeu.reactor-Jeu.Fast-Memory{
    background-color: var(--color-Fast-Memory-face);
}
.btn-jeu.reactor-border-jeux.Fast-Memory::after{
    background-color: var(--color-Fast-Memory);
}

.btn-jeu.reactor-Jeu.Coming{
    background-color: var(--color-Coming-face);
}
.btn-jeu.reactor-border-jeux.Coming::after{
    background-color: var(--color-Coming);
}


