*{
  font-family: 'Unbounded';
  margin: 0;
  padding: 0;
}


.reactor {
  text-align: center;
}

body{
  min-height: 100vh;
  background-image: url("components/background-Choix/background.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.reactor-header {
  min-height: 10vh;
  padding: 2vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: var(--color-princ);
  font-size: 5vmin;
}
.reactor-header p{
  font-size: 7vmin;
  font-weight: 900;
  margin-left: 4vmin;
}

.reactor-div-menu-titre{
  display: flex;
  align-items: center;
}


.reactor-block-info-header{
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: var(--color-ters);
  border: 0.7vmin solid var(--color-princ);
  border-radius: 2vmin;
  min-height: 4vmin;
  min-width: 4vmin;
  padding: 0.5vmin;
  font-size: 4vmin;
  margin-left: 10vmin;
  top: 0vmin;
  left: 0vmin;
  cursor: pointer;
  transition: all 0.3s;
}

.reactor-block-info-header::after{
  content: '';
  background-color: var(--color-second);
  border: 0.7vmin solid var(--color-princ);
  border-radius: 2vmin;
  min-height: 4vmin;
  min-width: 4vmin;
  padding: 0.5vmin;
  position: absolute;
  z-index: -1;
  top: 0.5vmin;
  left: 0.5vmin;
  transition: all 0.3s;
}
.reactor-block-info-header:hover{
  top: 1vmin;
  left: 1vmin;
}
.reactor-block-info-header:hover::after{
  top: -0.5vmin;
  left: -0.5vmin;
}

li.reactor-block-info-header{
  margin-left: 3vmin;
}



.reactor-inscription{
  font-size: 2vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  color: var(--color-princ);
}

.reactor-line{
  width: 0.1vmin;
  height: 4vmin;
  background-color: var(--color-princ);
  margin-left: 1vmin;
  margin-right: 1vmin;
}

.reactor-reseau{
  margin-right: 10vmin;
}

.reactor-reseau, .reactor-header ul{
  display: inherit;
  text-decoration: none;
  list-style: none;
}


@media screen and (max-width: 1000px) {
  .reactor-block-info-header {
    margin-left: 2vmin;
    font-size: 7vmin;
  }
  .reactor-inscription{
    font-size: 2.5vmin;
  }
  .reactor-block-info-header, .reactor-block-info-header::after{
    min-height: 7vmin;
    min-width: 7vmin;
  }
  .reactor-header p {
    font-size: 7vmin;
  }
  .reactor-reseau{
    display: none;
  }
  .reactor-header {
    min-height: 3vh;
  }
}



.reactor-lock{
  opacity: 65%;
}
