/* Menu latteral */

.reactor-div-lateral{
  position: absolute;
  width: max-content;
  height: 100vh;
  left: -100%;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


.reactor-menu-latteral{
    border-right: 1vmin solid var(--color-princ);
    border-top: 1vmin solid var(--color-princ);
    width: max-content;
    height: 98%;
    position: fixed;
    left: -100%;
    background-color:var(--color-second);
    transition: all 1s;
    border-top-right-radius: 10vmin;
    bottom: 0;
    box-shadow: 1vmin 1vmin 0vmin var(--color-princ);
    z-index: 11;
  }
  
  .reactor-div-menu-laterral-titre .reactor-block-info-header {
    margin-left: 0vmin;
    color: var(--color-princ);
  }
  
  .reactor-div-menu-laterral-titre{
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 4vmin;
    position: relative;
    left: -100%;
    padding-left: 6vmin;
    transition: all 1s;
  }
  
  .reactor-div-menu-laterral-titre p{
    font-size: 4vmax;
    font-weight: 900;
    margin-left: 4vmin;
    color: var(--color-ters);
  }
  
  .reactor-Div-list-reseau{
    color: var(--color-ters);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 80vh;
  }

  .reactor-list-nav{
    color: var(--color-ters);
    font-size: 2.7vmin;
    width: 100%;
    height: 100%;
    text-align: left;
    padding: 4vmin;
    position: relative;
    left: -100%;
    padding-left: 17vmin;
    transition: all 1s;
  }
  
  .reactor-list-nav ul{
    list-style:none;
    padding: 1vmin;
    padding-left: 6vmin;
  }
  
  .reactor-list-nav ul li{
    position: relative;
    cursor: pointer;
    margin-top: 0.7vmin;
  }
  .reactor-list-nav ul li::before{
    content: '';
    border: 0.1vmin solid var(--color-ters);
    min-width: 5vmin;
    position: absolute;
    top: 50%;
    transform: translate(-6vmin);
  }
  .reactor-list-nav ul li::after{
    content: '';
    border: 0.1vmin solid var(--color-ters);
    min-height: 50%;
    left: 0;
    position: absolute;
    transform: translate(-6vmin);
  }

  .reactor-list-nav ul li a{
    text-decoration:none;
    color: var(--color-ters);
  }
  
  .reactor-list-nav ul li a.reactor-choice-active{
    color: var(--color-princ);
  }
  
  .reactor-choice:hover{
    color: var(--color-quat);
  }
  
  
  .reactor-CloseOut-Menu-latteral{
    z-index: 10;
    width: 100vw;
    height: 100vh;
    position: absolute;
    right: 0;
    transition: all 1s;
    top: 0;
    cursor: pointer;
    z-index: 11;
    right: 100%;
  }
  
  
  .reactor-Div-list-reseau .reactor-reseau{
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 17vmin;
    flex-direction: column;
    align-items: flex-start;
  }

  .reactor-reseau-titr{
    margin-left: 1vmin;
    font-size: 2.5vmin;
  }
  
  .reactor-Div-list-reseau .reactor-reseau ul{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 2vmin;
    color: var(--color-princ);
    list-style: none;
  }

  .reactor-block-info-Nav{
    color: var(--color-ters);
    border: 0.5vmin solid var(--color-ters);
    border-radius: 30%;
    margin: 0.5vmin;
    padding: 1vmin;
    display: flex;
    transition: all 0.3s;
  }
  .reactor-block-info-Nav:hover{
    color: var(--color-princ);
    background-color: var(--color-ters);
    border: 0.5vmin solid var(--color-ters);
    border-radius: 30%;
    margin: 1vmin;
    padding: 1vmin;
    display: flex;
  }


  @media screen and (max-width: 1110px) {
    .reactor-info-menu-latteral{
      font-size: 4vmin;
    }
    .reactor-info-reseau-menu-latteral ul{
      font-size: 5vmin;
    }
  }