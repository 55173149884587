.reactor-text-presentation{
    text-align: left;
    font-size: 1.3vmin;
    margin-top: 3vmin;
    color: var(--color-princ);
    transition: all 2s;
}

@media screen and (max-width: 1000px) {
    .reactor-text-presentation{
        display: none;
    }
}


