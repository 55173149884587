$size: 83px;

.spinner-machine-container {
    position: relative;
    margin: 0 auto;
}

.spinner-lever-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    height: 1px;
    width: 1px;
}
.spinner-lever {
    position: absolute;
    left: 142px;
    top: 180px;
    height: 200px;
    transform-origin: bottom center;
    z-index: 10;
    transition: scale .2s ease;
    cursor: pointer;
    &:hover {
        scale: 1.1;
    }
}

.spinner-machine {
    position: absolute;
    top: 0;
    left: 50%;
    width: 400px;
    translate: -50%;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    position: relative;
    top: 227px;
    right: 30px;
    overflow: hidden;


    .spinner-slot {
        $delay: 0s;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        width: $size;
        min-width: $size;
        height: $size;
        
        .spinner-slot-container {
            position: absolute;
            bottom: Calc($size * -4);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        img {
            height: $size;
            padding: 14px;
        }
    }
}

@keyframes spin {
    0% {
        bottom: Calc($size * 0);
    }
    
    100% {
        bottom: Calc($size * -6);
    }
}
@keyframes endSpin {
    0% {
        bottom: Calc($size * -6.5);
    }
    30% {
        bottom: Calc($size * -5.8);
    }
    100% {
        bottom: Calc($size * -6);
    }
}
@keyframes lever {
    50% {
        scale: 1.1;
        rotate: -40deg;
    }
}





@media screen and (min-width: 1000px) {
    .spinner-machine-container {
        position: relative;
        margin: 0;
        bottom: 80%;
    }
}

@media screen and (max-width: 1000px) {
    .spinner-lever {
        left: 97px;
        top: 120px;
        height: 145px;
    }
    .spinner-machine {
        width: 280px;
    }
    .spinner-container .spinner-slot img {
        padding: 10px;
        padding-top: 35px;
    }
    .spinner-container {
        gap: 15px;
        top: 156px;
        right: 25px;
    }
    .spinner-container .spinner-slot {
        width: 51px;
        min-width: 51px;
        height: 61px;
    }
    
}