.page-game{
    height: 110vmin;
}

.reactor-decalage{
    width: 100%;
    height: 100%;
    padding: 0vmin calc(2vw + 10vmin);
}



/* bouton reduit jeux */

.reactor-block-Jeux{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.reactor-block-slide-Jeux{
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0vmin 3vmin;
}

.reactor-Jeu{
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--color-princ);
    border: 0.5vmin solid var(--color-princ);
    border-radius: 2vmin;
    position: relative;
    cursor: pointer;
}

.reactor-border-jeux::after{
    content: '';
    background-color: var(--color-second);
    border: 0.5vmin solid var(--color-princ);
    border-radius: 2vmin;
    min-height: 100%;
    min-width: 100%;
    padding: 0.2vmin;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}
.reactor-border-jeux.Cashore::after{
    background-color: var(--color-Cashor);
}

.reactor-border-jeux.x-ray::after{
    background-color: var(--color-X-Ray);
}

.reactor-jeu-titre{
    width: 100%;
    margin-top: 1vmin;
    padding: 2vmin;
    color: var(--color-princ);
    font-weight: 900;
    border-top: 0.5vmin solid var(--color-princ);
    background-color: var(--color-ters);
    border-radius: 0vmin 0vmin 2vmin 2vmin;
}


.reactor-div-info-jeu.reactor-Jeu.Cashor{
    background-color: var(--color-Cashor-face);
    background-image: url("../../../public/choix-jeux/cashor-detail-back.png");
}
.reactor-div-info-jeu.reactor-border-jeux.Cashor::after{
    background-color: var(--color-Cashor);
}

.reactor-div-info-jeu.reactor-Jeu.X-Ray{
    background-color: var(--color-X-Ray-face);
    background-image: url("../../../public/choix-jeux/cashor-detail-back.png");
}
.reactor-div-info-jeu.reactor-border-jeux.X-Ray::after{
    background-color: var(--color-X-Ray);
}

.reactor-div-info-jeu.reactor-Jeu.Fast-Memory{
    background-color: var(--color-Fast-Memory-face);
    background-image: url("../../../public/choix-jeux/cashor-detail-back.png");
}
.reactor-div-info-jeu.reactor-border-jeux.Fast-Memory::after{
    background-color: var(--color-Fast-Memory);
}

.reactor-div-info-jeu.reactor-Jeu.Coming{
    background-color: var(--color-Coming-face);
    background-image: url("../../../public/choix-jeux/cashor-detail-back.png");
}
.reactor-div-info-jeu.reactor-border-jeux.Coming::after{
    background-color: var(--color-Coming);
}