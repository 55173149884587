.formulaire-connexion{
    position: relative;
    min-width: 40%;
    min-height: 30%;
    width: max-content;
    height: max-content;
    border: 0.5vw solid var(--color-princ);
    background-color: var(--color-ters);
    border-radius: 2vw;
    padding: 5vmin;
}
.formulaire-connexion::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0.5vmin;
    left: 0.5vmin;
    border: 0.5vw solid var(--color-princ);
    border-radius: 2vw;
    background-color: var(--color-second);
}

.formulaire-titre{
    font-weight: 900;
    font-size: 3vmin;
    margin-bottom: 5vmin;
}




.formulaire-connexion hr{
    border: none;
    height: 2px;
    background-color: #ECECEC;
    margin-bottom: 2vmin;
}

.formulaire-change-etat{
    font-weight: 900;
    font-size: 2vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.formulaire-change-etat button{
    font-size: 2vmin;
    font-weight: 500;
    width: 90%;
    padding: 1vmin;
    background-color: var(--color-ters);
    color: var(--color-princ);
    border-radius: 5vmin;
    transition: all 0.3s;
    margin-top: 3vmin;
    cursor: pointer;
}

.formulaire-change-etat button:hover{
    background-color: #D9D9D9;
}