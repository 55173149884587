@import "variables";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font;
}
.button {
  $border-radius: 20px;
  position: relative;
  border: $border;
  border-radius: $border-radius;
  overflow: visible;
  background-color: var(--color-ters);
  transform-origin: top right;
  min-width: 200px;
  padding: 0 20px;
  margin: 10px;
  cursor: pointer;
  &:has(.button-close) {
    padding-top: 40px;
  }
  &:not(:has(*:not(.button-close))) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  &:after {
    content: '';
    position: absolute;
    transition: none;
    top: Min(25%, 12px);
    left: Min(8%, 12px);
    width: 100%;
    height: 100%;
    background: var(--color-second);
    border: $border;
    border-radius: $border-radius;
    z-index: -10!important;
  }
  .button-close {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    padding: 0 8%;
    z-index: 1;
    background-color: inherit;
    border-bottom: $border;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    pointer-events: none;
    transition: all .2s ease;
    
    &:hover:before {
      scale: 1.2;
    }
    
    &:before {
      content: '';
      pointer-events: all;
      transition: scale .2s ease;
      position: absolute;
      cursor: pointer;
      width: 20px;
      height: 20px;
      background-color: var(--color-second);
      border: $border;
      border-radius: 500px;
    }
  }
}