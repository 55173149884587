

.reactor-block-cashore{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-left: calc(2vw + 10vmin);
}


.reactor-cashore-phrase{
    text-align: left;
    color: var(--color-princ);
    font-weight: 900;
    width: 35%;
    margin-bottom: 2vmin;
}

.reactor-block-info-cashore{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reactor-div-infos-cashore{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 20%;
}

.reactor-div-info-cashore{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    margin: 2vmin 0vmin;
    width: 100%;
}

.reactor-img-mascot-golden{
    padding: 2vmin;
    padding-left: 4vmin
}
.reactor-img-mascot-mois{
    padding: 2vmin;
    padding-left: 4vmin
}

.reactor-info-cashore-txt{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: center;
}

.reactor-info-cashore-txt-titre{
    font-weight: 900;
    font-size: 2.3vmin;
}

.reactor-color-golden{
    color: var(--color-golden);
}
.reactor-color-mois{
    color: var(--color-mois);
}

.reactor-info-cashore-txt-texte{
    font-size: 1vmin;
}



.reactor-info-cashore-tete{
    background-color: var(--color-ters);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    padding: 0.5vmin 1vmin;
    position: absolute;
    top: 80%;
    right: 80%;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.reactor-info-cashore-vis{
    position: absolute;
}
.vistopleft{
    top: 0.5vmin;
    left: 0.5vmin;
}
.vistopright{
    top: 0.5vmin;
    right: 0.5vmin;
}
.visbottomright{
    bottom: 0.5vmin;
    right: 0.5vmin;
}

.reactor-shadow-info-cashore{
    background-color: var(--color-ters);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
}


.reactor-shadow-info-cashore::after{
    content: '';
    background-color: var(--color-second);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    min-height: 100%;
    min-width: 100%;
    padding: 0.1vmin;
    position: absolute;
    z-index: -1;
    top: 0vmin;
    left: 0vmin;
    transition: all 0.3s;
}
.reactor-shadow-info-cashore.golden::after{
    background-color: var(--color-golden);
}
.reactor-shadow-info-cashore.mois::after{
    background-color: var(--color-mois);
}

.verti{
    flex-direction: column;
}


.reactor-div-spinner-cashore{
    width: 75%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: center;
    position: relative;
}

.reactor-div-spinner-cashore .reactor-img-mascot{
    width: 450px;
    z-index: 1;
    height: auto;
    margin-bottom: 100px;
    margin-right: 20px;
    bottom: 0;
}
