.Back-connexion{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--color-second);
    overflow: hidden;
    
}
.head-back-connexion{
    background-color: transparent;
    background: url("../../components/background-Choix/background.png"), white;
    background-repeat: no-repeat;
    background-size: cover;
    width: 25%;
    height: 120vh;
    rotate: 5deg;
    position: relative;
    top: -3%;
    left: 60%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
}
.head-back-connexion img{
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
}
.formulaire-back-head-1{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}
.formulaire-back-head-2{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;
}
.spin1{
    animation: headspin1 10s linear infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3vmin;
}
.spin2{
    animation: headspin2 10s linear infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3vmin;
}
.spin3{
    animation: headspin1 10s reverse linear infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3vmin;
}
.spin4{
    animation: headspin2 10s reverse linear infinite;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 3vmin;
}

@keyframes headspin1{
    from {
        top: 0;
    }
    to {
        top: 100%;
    }
}
@keyframes headspin2{
    from {
        top: -100%;
    }
    to {
        top: 0;
    }
}



.block-connexion{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1;
    height: 100vh;
}

.div-info-connexion{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5vmin;
}

.connexion-titre{
    position: relative;
    background-color: var(--color-ters);
    padding: 1vmin 3vmin;
    border: 0.3vw solid var(--color-princ);
    border-radius: 5vw;
    width: max-content;
    font-weight: 900;
    font-size: 4vmin;
    cursor: pointer;
    text-decoration: none;
    color: var(--color-princ);
}
.connexion-titre::after{
    content: "";
    width: 100%;
    height: 100%;
    top: 0.2vmin;
    left: 0.2vmin;
    z-index: -1;
    border: 0.3vw solid var(--color-princ);
    background-color: var(--color-princ);
    border-radius: 5vw;
    position: absolute;
}

.connexion-info-skin{
    width: max-content;
    color: var(--color-ters);
}

.skin-texte-connexion{
    display: flex;
    text-align: left;
}

.limeo-connexion{
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 900;
    font-size: 2vmin;
}
.limeo-connexion img{
    margin-left: 1vmin;
}

.Div-formulaire-connexion{
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

