.xray-container {
    transform-origin: top center;
    scale: .5;
    .xray {
        display: grid;
        align-items: center;
        justify-content: center;
        img {
            grid-area: 1 / 1;
            --size: 120px;
            --x: -var(--size);
            width: Calc(416px + var(--size) * 2);
            transition: opacity 0.5s ease;
            cursor: none;
            
            &.base.hidden {
                mask: radial-gradient(circle var(--size) at var(--x, 50%) var(--y, 50%), #0000 98%, #000);
                mask-composite: exclude;
                opacity: 0.9;
            }
        }
    }
}

@media screen and (min-width: 1000px) {
    .xray-container {
        transform-origin: center;
        position: relative;
        scale: 0.9;
    }
    .xray-container .xray img {
        width: 550px;
    }
}

@media screen and (max-width: 1000px) {
    .xray-container {
        transform-origin: center top;
        position: absolute;
    }
    .xray-container .xray img {
        width: 500px;
    }
}
