
.reactor-block-X-ray{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    padding-left: calc(2vw + 10vmin);
}
.reactor-x-ray-phrase{
    text-align: left;
    color: var(--color-princ);
    font-weight: 900;
    width: 35%;
    margin-bottom: 2vmin;
}
.reactor-block-info-x-ray{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 50%;
}
.x-ray-block-affichage{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    height: auto;
    width: 100%;
}

.x-ray-box-affichage{
    background-color: var(--color-ters);
    position: relative;
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    padding: 3%;
}
.x-ray-box-affichage::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    top: 0.7vmin;
    left: 0.7vmin;
    z-index: -1;
    background-color: var(--color-fond-x-ray);
}

.x-ray-bords-affichage{
    background-color: var(--color-fond-x-ray);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1vmin;
    height: 100%;
    position: relative;
}

.x-ray-graduation{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.x-ray-mesure-affichage{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.x-ray-jeu-affichage{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0;
}

.croix{
    position: absolute;
}

.haut{
    top: 1vmin;
}
.bas{
    bottom: 1vmin;
}
.droite{
    right: 1vmin;
}
.gauche{
    left: 1vmin;
}

/* partie : "panneau de commande" */

.x-ray-block-panneau-commande-affichage{
    width: 100%;
    height: max-content;
    padding-left: calc(2vw + 10vmin);
    padding-right: calc(2vw + 10vmin);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.x-ray-btns-change{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding: 0vmin 1vmin;
    margin-top: 5vmin;
}

.x-ray-bas-btn-change{
    background-color: var(--color-ters);
    position: relative;
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    width: 100%;
    padding-bottom: 2vmin;
    margin-bottom: 2vmin;
}
.x-ray-bas-btn-change::after{
    content: "";
    position: absolute;
    background-color: var(--color-commande1);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    width: 100%;
    height: 100%;
    top: 0.5vmin;
    left: 0.5vmin;
    z-index: -1;
}
.x-ray-bas-btn-change-ombre{
    background-color: var(--color-commande1);
    width: 100%;
    height: 10px;
}

.box-arriere-commande{
    position: relative;
    background-color: var(--color-ters);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    margin: 2vmin 0vmin;
}
.box-arriere-commande::after{
    content: "";
    position: absolute;
    background-color: var(--color-commande1);
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    width: 100%;
    height: 100%;
    top: 0.5vmin;
    left: 0.5vmin;
    z-index: -1;
}

.x-ray-info-block{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2vmin;
}

.x-ray-info-block-texte{
    font-weight: 900;
    color: #606063;
    padding-left: 1vmin;
    text-align: left;
    font-size: 2vmin;
}

.x-ray-bar-rayer{
    background-image: url(../../../public/x-ray-img/rayer.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: max-content;
    padding: 3vmin;
    position: relative;
    border: 0.7vmin solid var(--color-princ);
    margin: 2vmin 0vmin;
}
.x-ray-bar-rayer::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0.7vmin;
    left: 0.7vmin;
    background-color: #BDCAF4;
    z-index: -1;
    border: 0.7vmin solid var(--color-princ);
}


.x-ray-bouton-bas-box{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.x-ray-cercle-bas-box{
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

.x-ray-mesure{
    padding: 0.3vmin 1.5vmin;
}