
.x-ray-btn-change{
    width: 100%;
    margin: 0vmin 0.7vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.x-ray-btn-change-head{
    width: 95%;
    height: 100%;
    border: 0.7vmin solid var(--color-princ);
    border-bottom: none;
    border-top-right-radius: 3vmin;
    border-top-left-radius: 3vmin;
    position: relative;
    transition: all 0.5s;
    padding-top: 1vmin;
    margin-top: 5vmin;
}
.x-ray-btn-change-head img{
    width: 95%;
    animation: 2s linear 0s infinite alternate headMouve;
}
.x-ray-btn-change-head.Bones{
    background-color: var(--color-tuyaux-bones);
}
.x-ray-btn-change-head.Organs{
    background-color: var(--color-tuyaux-organs);
}

.btn-head-active{
    padding-top: 5vmin!important;
    margin-top: 1vmin!important;
}
.btn-head-active img{
    animation: 2s linear 0s infinite alternate headMouveActive;
}

@keyframes headMouveActive {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-20%);
    }
}
@keyframes headMouve {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-5%);
    }
}

.ombre{
    position: absolute;
}

.ombre2{
    height: 100%;
    width: 10%;
    opacity: 50%;
    border-top-left-radius: 3vmin;
    position: absolute;
}
.ombre2::after{
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
}

.ombre3{
    height: 100%;
    width: 10%;
    opacity: 50%;
    position: absolute;
}
.ombre3::before{
    content: "";
    position: absolute;
    height: 10%;
    top: 0;
    right: 100%;
}

.ombre2.Bones{
    background-color: var(--color-ombre2-Bones);
}
.ombre2.Bones::after{
    border-top: 3vmin solid var(--color-ombre2-Bones);
    border-right: 1vmin solid transparent;
}

.ombre2.Organs{
    background-color: var(--color-ombre2-Organs);
}
.ombre2.Organs::after{
    border-top: 3vmin solid var(--color-ombre2-Organs);
    border-right: 1vmin solid transparent;
}

.ombre3.Bones{
    background-color: var(--color-ombre3-Bones);
}
.ombre3.Bones::before{
    border-top: 3vmin solid var(--color-ombre3-Bones);
    border-left: 1vmin solid transparent;
}

.ombre3.Organs{
    background-color: var(--color-ombre3-Organs);
}
.ombre3.Organs::before{
    border-top: 3vmin solid var(--color-ombre3-Organs);
    border-left: 1vmin solid transparent;
}



.x-ray-ombre1-bocale{
    top: 0.7vmin;
    left: 0.7vmin;
}

.x-ray-ombre2-bocale{
    top: 0vmin;
    left: 4vmin;
    height: 100%;
}

.x-ray-ombre3-bocale{
    top: 0vmin;
    right: 0vmin;
    height: 100%;
    border-top-right-radius: 3vmin;
}

.x-ray-btn-change-socle{
    width: 100%;
    background-color: var(--color-commande1);
    height: 100%;
    padding: 1vmin;
    border: 0.7vmin solid var(--color-princ);
    border-bottom: none;
    position: relative;
}

.x-ray-btn-change-socle-type{
    background-color: var(--color-ters);
    color: #606063;
    font-weight: 900;
    border: 0.7vmin solid var(--color-princ);
    border-radius: 1vmin;
    position: absolute;
    padding: 0.5vmin 1vmin;
    bottom: 0.7vmin;
    left: 3vmin;
}

