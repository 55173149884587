
.boutique-affiche-div{
    height: 20vw;
    width: 13vw;
    position: relative;
    margin: 5vmin;
    margin-top: 0;
}
.boutique-affiche-div.mois{
    width: calc(13vw * 2 + 10vmin);
}
.boutique-affiche-div:hover{
    z-index: 10;
}

.boutique-affiche-box{
    background-color: var(--color-ters);
    border: 0.4vmin solid var(--color-princ);
    border-radius: 3vmin;
    position: absolute;
    width: 100%;
    height: 100%;
}
.boutique-affiche-box.mois{
    background-image: url("/src/components/skin-du-mois/back-mois.png")!important;
}
.boutique-affiche-box:hover{
    height: 120%;
    width: 120%;
    right: -10%;
    top: -10%;
}


.boutique-affiche-box .Boutique-affiche-skin{
    margin-top: 1vmin;
    height: 80%;
    width: auto;
    position: relative;
}
.boutique-affiche-box::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0.3vmin;
    top: 0.3vmin;
    z-index: -1;
    border: 0.4vmin solid var(--color-princ);
    border-radius: 3vmin;
}

.Boutique-affiche-block-skin{
    width: 100%;
    height: 75%;
    position: relative;
}
.Boutique-affiche-block-info{
    width: 100%;
    height: 35%;
    bottom: 10%;
    position: relative;
    border-bottom-left-radius: 3vmin;
    border-bottom-right-radius: 3vmin;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-top: 0.3vw solid var(--color-princ);
}

.Boutique-affiche-block-info.mois{
    align-items: flex-start;
    padding-left: 2.5vw;
}

.Boutique-affiche-info-nom{
    font-weight: 900;
    font-size: 1vw;
    margin: 0.1vw;
}
.Boutique-affiche-info-cita{
    font-weight: 200;
    font-size: 0.7vw;
    margin: 0.1vw;
    margin-bottom: 2.3vw;
}

.Boutique-affiche-prix-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 80%;
    z-index: 1;
    width: 55%;
    font-weight: 900;
}
.Boutique-affiche-prix-div.mois{
    width: 25%;
}

.Boutique-affiche-prix{
    background-color: var(--color-ters);
    width: 90%;
    right: 0;
    padding: 0.3vw;
    border: 0.3vw solid var(--color-princ);
    border-top-right-radius: 1vw;
    border-bottom-right-radius: 1vw;
    border-left: none;
    position: absolute;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    display: flex;
    justify-content: flex-end;
    z-index: -1;
}
.boutique-affiche-piece{
    position: relative;
}

.hidde{
    display: none;
}

.Boutique-affiche-button-achete{
    width: 10vw;
    padding: 1vw;
    font-weight: 500;
    background-color: var(--color-button-achete);
    color: var(--color-ters);
    border: none;
    border-radius: 1vw;
    cursor: pointer;
    margin: -2vw 0vw 1vw 0vw;
    position: relative;
}


.boutique-affiche-box-mois{
    background-color: var(--color-ters);
    border: 0.4vmin solid var(--color-princ);
    border-radius: 1vmin;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 1vmin;
}
.boutique-affiche-box-mois::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0.3vmin;
    top: 0.3vmin;
    z-index: -1;
    border: 0.4vmin solid var(--color-princ);
    border-radius: 1vmin;
}

.mois-color-box-div{
    position: absolute;
    top: 1vmin;
    left: 1vmin;
    z-index: 1;
}

.mois-color-box{
    font-weight: 900;
    font-size: 3vmin;
    padding: 0.3vmin;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.mois-color-box::after{
    background-color: var(--color-mois-box);
}



.boutique-affiche-box.mois .Boutique-affiche-skin{
    height: 80%;
    width: auto;
}

.boutique-affiche-box.mois:after{
    background-color: var(--color-mois);
}





