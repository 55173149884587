.reactor-main-b{
    height: 120vmin;
}
.reactor-main-boutique{
    width: 100%;
    height: 100vmin;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}


.reactor-nav-boutique{
    color: var(--color-second);
    margin-bottom: 2vw;
    margin-left: calc(2vw + 10vmin);
}


.reactor-container-boutique {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    padding-left: calc(2vw + 10vmin);
    padding-right: calc(2vw + 10vmin);
}

.reactor-div-boutique{
    display: flex;
    flex-direction: row;
    align-items: center;
}