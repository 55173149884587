:root {
    /* COULEURS */
    /* couleurs principals */
    --color-princ: #000000;
    --color-second: #BC284A;
    --color-ters: white;
    --color-quat: gray;
    --color-credits: #F9AC30;
    --color-golden: #FFD392;
    
    /* couleur choix jeu */
    --color-Cashor: #A47CE4;
    --color-Cashor-face: #4D0869;
    --color-X-Ray: #90B9DF;
    --color-X-Ray-face: #3CC9D2;
    --color-Fast-Memory: #1D1D1D;
    --color-Fast-Memory-face: #949494;
    --color-Coming: #F7B41E;
    --color-Coming-face: #DD5959;

    /* couleur Boutique */
    --color-button-achete: #639FBC;

    --color-mois: #B3E782;
    --color-mois-box: #8B55A1;
    --color-Classic: #E16464;
    --color-Classic-back: #A43F3F;
    --color-Dark: #362A2A;
    --color-Dark-back: #D20808;
    --color-Iron: #D37557;
    --color-Iron-back: #F4CE53;
    --color-Robocorn: #639FBC;
    --color-Robocorn-back: #ACDCF3;
    --color-Bones: #49BBC2;
    --color-Bones-back: #89E8EE;
    --color-Organ: #AC2F40;
    --color-Organ-back: #FF6076;

    /* couleur jeux X-ray */
    --color-tuyaux-bones: #69DAE1;
    --color-tuyaux-organs: #EA4E63;
    --color-fond-x-ray: #20475C;
    --color-commande1: #CBD1E8;
    --color-ombre2-Bones: #9BF9FF;
    --color-ombre3-Bones: #43B5BC;
    --color-ombre2-Organs: #FCA6B2;
    --color-ombre3-Organs: #C22238;
}


.reactor-Colorsecond{
    color: var(--color-second);
}
  
.reactor-Colorprinc{
    color: var(--color-princ);
}
  