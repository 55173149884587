
.reactor-div-head-estim{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5vmin 0.5vmin;
    font-weight: 900;
    font-size: 2vmin;
}

.reactor-img-mascot-head{
    width: 15%;
    height: auto;
}

.reactor-img-credits-info-cashore{
    width: 10%;
    height: auto;
}