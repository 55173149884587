.x-ray-cercle{
    padding: 0.7vmin;
    border-radius: 100%;
    margin-right: 5vmin;
}
.x-ray-cercle::after{
    border-radius: 100%;
    top: 0.1vmin;
    left: 0.1vmin;
}
.x-ray-cercle-interieur{
    padding: 1.3vmin;
    border: 0.7vmin solid var(--color-princ);
    border-radius: 2vmin;
    transition: all 1s;
}
.x-ray-cercle-interieur.tuyaux1{
    background-color: var(--color-tuyaux-organs);
}
.x-ray-cercle-interieur.tuyaux2{
    background-color: var(--color-tuyaux-bones);
}