
.x-ray-tuyaux-box{
    width: 100%;
    height: 100%;
    display: flex;
    padding-top: 5vmin;
    margin-right: -0.5vmin;
    transition: all 1s;
}


