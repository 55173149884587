
.reactor-footer{
  background-color: var(--color-second);
  bottom: 0;
  position:fixed;
  height: 5.7vmin;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  border-top-left-radius: 2vmin;
  border-top-right-radius: 2vmin;
  z-index: 20;
}

.reactor-footer-block-info{
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.reactor-footer-credits{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 10vmin;
  width: 10vmin;
  background-color: #F4F4F4;
  border-top-left-radius: 3vmin;
  border-top-right-radius: 3vmin;
  margin-left: calc(2vw + 10vmin);
  margin-right: 0.7vmin;
}

.reactor-img-credits{
  top: 0;
  width: 50%;
}


.reactor-footer-titre-credits{
  width: max-content;
  height: 100%;
  font-size: 1vmin;
  background-color: var(--color-credits);
  border-radius: 5vmin;
  padding: 0.5vmin 1.5vmin 0.5vmin 1.5vmin;
  margin: 0.5vmin 1vmin 1.5vmin 1vmin;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reactor-footer-nombre-credits{
  border-radius: 100vmin;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.reactor-img-perso{
  position: relative;
  max-width: 17%;
  height: auto;
  margin: 2vmin;
}


.reactor-footer-perso{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 35vw;
  border-top-left-radius: 1vmin;
  border-top-right-radius: 5vmin;
  height: 10vmin;
}

.reactor-shadow{
  background-color: var(--color-ters);
  border: 0.5vmin solid var(--color-princ);
  border-bottom: none;
  box-shadow: 1vmin 1vmin 0px var(--color-princ);
}

.reactor-block-footer-parole{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  height: 100%;
  width: 100%;
  padding-left: 1vmin;
}

.reactor-footer-message{
  font-size: 1.3vmin;
}

/* partie info du footer */

.reactor-block-info-footer{
  color: var(--color-ters);
  width: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 5vmin;
}
.reactor-block-info-footer b{
  margin-left: 0.5vmin;
}

.reactor-div-number-credits{
  position: relative;
}

