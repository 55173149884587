form{
    width: 100%;
}

form label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    font-weight: 900;
    margin-top: 4vmin;
}

form label input{
    width: 100%;
    padding: 1vmin;
    margin-top: 0.5vmin;
    border-radius: 5vmin;
}

.formulaire-input-mdp{
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 0.5vmin;
}
.formulaire-input-mdp input{
    margin: 0;
}
.formulaire-input-mdp img{
    position: absolute;
    right: 1vmin;
    z-index: 1;
    cursor: pointer;
}


.formulaire-img-eye-mdp{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.eye{
    display: none;
}
.eye-close{
    display: block;
}


.div-formulaire-oublier{
    width: 100%;
    text-align: left;
}

.formulaire-oublie{
    width: 100%;
    color: var(--color-second);
    text-decoration: none;
    margin-top: 2vmin;
    font-size: 500;
    font-size: 1.5vmin;
}

.checkbox-formulaire{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    font-size: 1vmin;
    margin-top: 1vmin;
}
.checkbox-formulaire input{
    width: max-content;
    margin-right: 1vmin;
    margin-top: 0vmin;
    border-color: #F7F7F7;
    cursor: pointer;
}

.submit{
    background-color: var(--color-second);
    border-color: var(--color-second);
    color: var(--color-ters);
    border-radius: 5vmin;
    padding: 0.7vmin 10%;
    margin: 2vmin;
    cursor: pointer;
    transition: all 0.3s;
}
.submit:hover{
    border-radius: 5vmin;
    padding: 0.7vmin 15%;
    margin: 2vmin;
}