.reactor-titre-presentation{
    text-align: left;
    font-size: 2.5vmin;
    font-weight:900;
    margin-top: 3vmin;
  }
  @media screen and (max-width: 1000px) {
    .reactor-titre-presentation{
      text-align: center;
    }
  }