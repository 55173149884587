
.bouton-inscription-div{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2vmin;
}

.bouton-inscription{
    font-size: 2.3vmin;
    font-weight: 500;
    width: 90%;
    padding: 1vmin;
    background-color: var(--color-ters);
    color: var(--color-princ);
    border-radius: 5vmin;
    margin-bottom: 2vmin;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 5vmin;
    transition: all 0.3s;
    cursor: pointer;
}
.bouton-inscription img{
    margin-right: 1vmin;
}
.bouton-inscription:hover{
    background-color: #D9D9D9;
}