

.reactor-je-veux-jouer{
    color: var(--color-princ);
    background-color: #F9EBFF;
    border-radius: 1.5vmin;
    width: max-content;
    padding: 1vmin 2.5vmin;
    margin-top: 2vmin;
    cursor: pointer;
    font-weight: 900;
    font-size: 2vmin;
    transition: all 0.3s;
    text-decoration: none;
    z-index: 10;
}

.reactor-je-veux-jouer:hover{
    color: var(--color-ters);
    background-color: var(--color-princ);
}
